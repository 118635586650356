/* @flow */

import type { TimeSlotLocation } from '@braindate/domain/lib/base/type';
import type { Reaction } from '@braindate/domain/lib/reaction/type';
import { topicModerationVerifiedStatus } from '@braindate/domain/lib/topic/setting';
import type { Topic, TopicKind } from '@braindate/domain/lib/topic/type';
import {
  getTopicCancelURL,
  getTopicCohostsURL,
  getTopicFavoriteURL,
  getTopicId,
  getTopicLeaveURL,
  getTopicShareURL,
  getTopicWatchURL,
  isTopicFavorite,
} from '@braindate/domain/lib/topic/util';
import type { User } from '@braindate/domain/lib/user/type';
import { getUserURL } from '@braindate/domain/lib/user/util';

import { TAG_TYPE } from 'src/shared/app/base/api/apiConstant';
import { apiRoot } from 'src/shared/app/base/api/apiRoot';
import { defaultLimitPages } from 'src/shared/app/base/api/constant/apiConstant';
import type {
  ApiListResult,
  UseMutation,
  UseQuery,
} from 'src/shared/app/base/api/type/apiQueryType';
import { parseErrorResponse } from 'src/shared/app/base/api/utils/customQuery';
import {
  getQueryTag,
  infiniteLoadingSettings,
  optimisticUpdate,
} from 'src/shared/app/base/api/utils/endpointUtils';
import {
  topicCanDeleteEndpoint,
  topicCanJoinEndpoint,
  topicEndpoint,
  topicReactionsEndpoint,
  topicsCountEndpoint,
  topicsEndpoint,
} from 'src/shared/app/base/settings/endpointSettings';

type Api = {
  useGetTopicQuery: UseQuery<Topic, number>,
  useGetTopicCanJoinQuery: UseQuery<
    { can_join: boolean, message?: string },
    number,
  >,
  useGetTopicCanDeleteQuery: UseQuery<
    { can_delete: boolean, message?: string },
    { id: number },
    { error: string },
  >,
  useGetTopicReactionsQuery: UseQuery<
    ApiListResult<User>,
    { topic: Topic, page?: number, type?: Reaction },
  >,

  useGetTopicsForMarketQuery: UseQuery<ApiListResult<Topic>>,
  useGetTopicsByAuthorQuery: UseQuery<ApiListResult<Topic>>,
  useGetTopicsCountByAuthorQuery: UseQuery<ApiListResult<Topic>>,

  useTopicCreateMutation: UseMutation<TopicCreateParams, Topic>,
  useTopicUpdateMutation: UseMutation<TopicUpdateParams, Topic>,
  useTopicBookmarkMutation: UseMutation<Topic, Topic>,

  useTopicCancelMutation: UseMutation<Topic, void>,
  useTopicLeaveMutation: UseMutation<Topic, void>,
  useTopicDeleteMutation: UseMutation<Topic, void>,
  useTopicHideMutation: UseMutation<Topic, Topic>,
  useTopicAddCohostMutation: UseMutation<
    { topic: Topic, user: User },
    { cohosts: User[], error?: string },
  >,
  useTopicRemoveCohostMutation: UseMutation<
    { topic: Topic, user: User },
    Topic,
  >,
  useTopicShareMutation: UseMutation<Topic, SocialSharingData>,
  useTopicWatchMutation: UseMutation<{ topic: Topic, enabled: boolean }, void>,
};

const extendedApi: Api = apiRoot.injectEndpoints({
  endpoints: (builder) => ({
    getTopic: builder.query({
      query: (id: number) => ({
        url: topicEndpoint(id),
      }),
      providesTags: (result) =>
        result ? getQueryTag(TAG_TYPE.TOPIC, getTopicId(result)) : [],
    }),
    getTopicCanJoin: builder.query({
      query: (id: number) => ({
        url: topicCanJoinEndpoint(id),
      }),
    }),
    getTopicCanDelete: builder.query({
      query: ({ id }) => ({
        url: topicCanDeleteEndpoint(id),
      }),
    }),
    getTopicReactions: builder.query({
      query: ({
        topic,
        page,
        label,
      }: {
        topic: Topic,
        page: number,
        label?: Reaction,
      }) => ({
        url: topicReactionsEndpoint(),
        params: {
          topic: getTopicId(topic),
          limit: defaultLimitPages,
          offset: (page - 1) * defaultLimitPages,
          ...(label ? { label } : {}),
        },
      }),
    }),

    getTopicsForMarket: builder.query({
      query: ({ params, page = 1 }) => ({
        url: topicsEndpoint(),
        params: {
          ...params,
          limit: defaultLimitPages,
          offset: (page - 1) * defaultLimitPages,
          moderation: topicModerationVerifiedStatus,
          visible: true,
          spots_left: true,
          for_marketplace: true,
        },
      }),
      ...infiniteLoadingSettings<Topic>({
        tagType: TAG_TYPE.TOPIC,
        idGetter: getTopicId,
      }),
    }),

    getTopicsCountByAuthor: builder.query({
      query: ({ id }: { id: number | number[] }) => ({
        url: topicsCountEndpoint(),
        params: {
          author_id: id,
        },
      }),
    }),
    getTopicsByAuthor: builder.query({
      query: ({ id, page = 1, limit, ...params }) => ({
        url: topicsEndpoint(),
        params: {
          author_id: id,
          limit: limit || defaultLimitPages,
          offset: (page - 1) * defaultLimitPages,
          ...params,
        },
      }),
      ...infiniteLoadingSettings({
        tagType: TAG_TYPE.TOPIC,
        idGetter: getTopicId,
      }),
    }),

    // Actions
    topicCreate: builder.mutation({
      query: (params: TopicCreateParams) => ({
        url: topicsEndpoint(),
        method: 'POST',
        params,
      }),
      invalidatesTags: getQueryTag(TAG_TYPE.TOPIC),
      transformErrorResponse: (response) => {
        return parseErrorResponse(response);
      },
    }),
    topicUpdate: builder.mutation({
      query: ({ id, ...params }: TopicUpdateParams) => ({
        url: topicEndpoint(id),
        method: 'PATCH',
        params,
      }),
      invalidatesTags: (result, _, { id }) => getQueryTag(TAG_TYPE.TOPIC, id),
    }),
    topicHide: builder.mutation({
      query: (topic) => ({
        url: topicEndpoint(getTopicId(topic)),
        method: 'PATCH',
        params: {
          is_visible: false,
        },
      }),
      invalidatesTags: (result, _, { id }) => [{ type: TAG_TYPE.TOPIC, id }],
    }),
    topicDelete: builder.mutation({
      query: (topic) => ({
        url: topicEndpoint(getTopicId(topic)),
        method: 'DELETE',
      }),
      invalidatesTags: (result, _, { id }) => getQueryTag(TAG_TYPE.TOPIC, id),
    }),
    topicBookmark: builder.mutation({
      query: (topic: Topic) => ({
        url: getTopicFavoriteURL(topic),
        method: 'POST',
        params: { bookmarked: !isTopicFavorite(topic) },
      }),
      onQueryStarted(topic: Topic, mutation) {
        optimisticUpdate<Topic>({
          extendedApi,
          mutation,
          type: TAG_TYPE.TOPIC,
          id: topic.id,
          callback: (draft) => {
            draft.bookmarked = !draft.bookmarked;
            return draft;
          },
        });
      },
      invalidatesTags: (result, _, { id }) => getQueryTag(TAG_TYPE.TOPIC, id),
    }),
    topicCancel: builder.mutation({
      query: (topic) => ({
        url: getTopicCancelURL(topic),
        method: 'POST',
      }),
      invalidatesTags: (result, _, { id }) => [
        { type: TAG_TYPE.TOPIC, id },
        TAG_TYPE.CONVERSATION,
        TAG_TYPE.BRAINDATE,
      ],
    }),
    topicLeave: builder.mutation({
      query: (topic) => ({
        url: getTopicLeaveURL(topic),
        method: 'POST',
      }),
      invalidatesTags: (result, _, { id }) => [
        { type: TAG_TYPE.TOPIC, id },
        TAG_TYPE.CONVERSATION,
        TAG_TYPE.BRAINDATE,
      ],
    }),
    topicAddCohost: builder.mutation({
      query: ({ topic, user }) => ({
        url: getTopicCohostsURL(topic),
        method: 'PUT',
        params: {
          user: getUserURL(user),
        },
      }),
      invalidatesTags: (result, _, { id }) => getQueryTag(TAG_TYPE.TOPIC, id),
    }),
    topicRemoveCohost: builder.mutation({
      query: ({ topic, user }) => ({
        url: getTopicCohostsURL(topic),
        method: 'DELETE',
        params: {
          user: getUserURL(user),
        },
      }),
      invalidatesTags: (result, _, { id }) => getQueryTag(TAG_TYPE.TOPIC, id),
    }),
    topicShare: builder.mutation({
      query: (topic: Topic) => ({
        url: getTopicShareURL(topic),
        method: 'POST',
        params: {
          share: true,
        },
      }),
      invalidatesTags: (_result, _, { id }) => getQueryTag(TAG_TYPE.TOPIC, id),
    }),
    topicWatch: builder.mutation({
      query: ({ topic, enabled }: { topic: Topic, enabled: Boolean }) => ({
        url: getTopicWatchURL(topic),
        method: 'POST',
        params: {
          enabled,
        },
      }),
      invalidatesTags: (_result, _, { id }) => getQueryTag(TAG_TYPE.TOPIC, id),
    }),
  }),
});

export const {
  useGetTopicQuery,
  useGetTopicCanJoinQuery,
  useGetTopicCanDeleteQuery,
  useGetTopicReactionsQuery,

  useGetTopicsForMarketQuery,
  useGetTopicsByAuthorQuery,
  useGetTopicsCountByAuthorQuery,

  useTopicCreateMutation,
  useTopicUpdateMutation,
  useTopicBookmarkMutation,
  useTopicCancelMutation,
  useTopicLeaveMutation,
  useTopicDeleteMutation,
  useTopicHideMutation,
  useTopicAddCohostMutation,
  useTopicRemoveCohostMutation,
  useTopicShareMutation,
  useTopicWatchMutation,
} = extendedApi;

export type TopicCreateParams = {
  body: {
    [key: string]: { title: string, how: string, keywords: string[] },
  },
  kind: TopicKind,
  braindate_format?: string,
  recorded?: boolean,
  date?: TimeSlotLocation,
  claimed_from?: number,
};

export type TopicUpdateParams = {
  id: number,
} & TopicCreateParams;

export type SocialSharingData = {
  url: string,
  image_url: string | null,
};
