/* @flow */

import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ampli,  } from 'src/ampli';
import useUser from 'src/shared/components/domain/user/hooks/useUser';
import { getAmplitudeKey } from '../selector/appEnvSelectors';

export default function useAmplitudeTracking() {
  const {data} = useUser('me');
  const key = useSelector(getAmplitudeKey);


  useEffect(() => {
    ampli.load({client: {
      apiKey: key,
      configuration: {
        defaultTracking: true
      },
    }});
  }, []);

  useEffect(() => {
    if(ampli.isLoaded && data?.id){
      ampli.identify(data.uuid)
    }
  }, [data, ampli.isLoaded]);
}
