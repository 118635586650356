/* @flow */

/**
 * WARNING: because of how routes are registered in `useRegistrationRoute`,
 * this file should only export `RouteData` types.
 *
 * The not found route MUST be the last one defined.
 */

import { either } from 'ramda';

// Templates

// Pages

import { isEventContextOnSite } from '@braindate/domain/lib/event/util';

import LazyAvailabilityMain from 'src/shared/app/account/base/component/LazyAvailabilityMain';
import LazyOwnTopicsMain from 'src/shared/app/account/base/component/LazyOwnTopicsMain';
import LazyProfileMain from 'src/shared/app/account/base/component/LazyProfileMain';
import LazyOauthRedirect from 'src/shared/app/account/oauth/components/LazyOauthRedirect';
import LazyProfileEditMain from 'src/shared/app/account/profile/component/edit/LazyProfileEditMain';
import LazyLoginMain from 'src/shared/app/authentication/component/login/LazyLoginMain';
import LazyResetPasswordMain from 'src/shared/app/authentication/component/reset-password/LazyResetPasswordMain';
import LazySupportContactMain from 'src/shared/app/authentication/component/support/contact/LazySupportContactMain';
import LazyLoginSupportMain from 'src/shared/app/authentication/component/support/login/LazyLoginSupportMain';
import LazyLoginLinkMain from 'src/shared/app/authentication/component/support/login/link/LazyLoginLinkMain';
import LazyRetrieveUsernameMain from 'src/shared/app/authentication/component/support/login/username/LazyRetrieveUsernameMain';
import LazyPasswordSupportMain from 'src/shared/app/authentication/component/support/password/LazyPasswordSupportMain';
import LazyValidateEmailMain from 'src/shared/app/authentication/component/validate-email/LazyValidateEmailMain';
import { getAuthenticationToken } from 'src/shared/app/authentication/selector/base/appAuthenticationBaseSelectors';
import { getUser } from 'src/shared/app/base/api/endpoint/usersEndpoint';
import BlankTemplate from 'src/shared/app/base/component/template/BlankTemplate';
import BlankWithDesktopSideBarTemplate from 'src/shared/app/base/component/template/BlankWithDesktopSideBarTemplate';
import FullWidthTemplate from 'src/shared/app/base/component/template/FullWidthTemplate';
import OnboardingTemplate from 'src/shared/app/base/component/template/OnboardingTemplate';
import StretchedWidthTemplate from 'src/shared/app/base/component/template/StretchedWidthTemplate';
import { loginPath } from 'src/shared/app/base/route/setting/routePaths';
import type { RouteData } from 'src/shared/app/base/route/type/routeTypes';
import {
  redirectToEmailValidationUnlessValidated,
  redirectToHomeWhenEmailValidated,
  redirectToHomeWhenSignupCompleted,
  redirectToLoginIfOnboardingCompletedOnDevice,
  redirectToLoginURL,
  redirectToSignupUnlessCompleted,
  redirectWhenRedirectAndAuthenticated,
  standardAuthenticatedPageRedirect,
  standardUnauthenticatedPageRedirect,
} from 'src/shared/app/base/route/util/redirectUtils';
import {
  isPermissionScreenShown,
  isSplashScreenShown,
} from 'src/shared/app/base/route/util/routeUtilsSelectors';
import LazyBlankMain from 'src/shared/app/blank/LazyBlankMain';
import LazyBraindateMain from 'src/shared/app/braindate/component/LazyBraindateMain';
import LazyBraindatesMain from 'src/shared/app/braindate/component/main/LazyBraindatesMain';
import LazyCodeOfConductMain from 'src/shared/app/code_of_conduct/component/LazyCodeOfConductMain';
import LazyConversationMain from 'src/shared/app/conversation/component/LazyConversationMain';
import LazyClientErrorMain from 'src/shared/app/error/component/LazyClientErrorMain';
import LazyServerErrorMain from 'src/shared/app/error/component/LazyServerErrorMain';
import LazyFAQMain from 'src/shared/app/faq/component/LazyFAQMain';
import FluidLoadingScreen from 'src/shared/app/loading/component/FluidLoadingScreen';
import LazyMarketMain from 'src/shared/app/market/component/MarketMain';
import LazyOnlineUsersMain from 'src/shared/app/market/component/online-users/LazyOnlineUsersMain';
import LazyNotFoundMain from 'src/shared/app/not-found/component/LazyNotFoundMain';
import LazyNotificationsPreferencesMain from 'src/shared/app/notification-preference/components/LazyNotificationsPreferencesMain';
import LazySplashScreen from 'src/shared/app/onboarding/component/splash/LazySplashScreen';
import LazyPermissionMain from 'src/shared/app/permission/component/LazyPermissionMain';
import LazyPreferencesMain from 'src/shared/app/preference/component/LazyPreferencesMain';
import LazySignupMain from 'src/shared/app/signup/component/LazySignupMain';
import LazySupportMain from 'src/shared/app/support/component/LazySupportMain';
import LazyNewTopicMain from 'src/shared/app/topic/new/component/LazyNewTopicMain';
import LazyNewTopicNoPermissionMain from 'src/shared/app/topic/new/component/LazyNewTopicNoPermissionMain';
import LazyInviteMain from 'src/shared/app/user/component/invite/LazyInviteMain';
import LazyUserMain from 'src/shared/app/user/component/LazyUserMain';
import LazyTopicDetailsMain from 'src/shared/components/domain/topic/details/LazyTopicDetailsMain';
import LazyTopicEditMain from 'src/shared/components/domain/topic/form/LazyTopicEditMain';
import { getEventSafely } from 'src/shared/domain/event/selector/eventSelectors';

import LazyLightMain from 'plugin/light/component/LazyLightMain';

export const homeRoute: RouteData = {
  path: '/',
  template: (state) => {
    if (isSplashScreenShown(state, getEventSafely(state))) {
      return BlankTemplate;
    }

    // @TODO: Use a better loading component for this (Created in https://bitbucket.org/e180/braindate/pull-requests/1972)
    // Could be shown when logging from BOS
    if (!getEventSafely(state) || !getUser.select('me')(state).data) {
      return FluidLoadingScreen;
    }

    return FullWidthTemplate;
  },
  main: (state) => {
    if (isSplashScreenShown(state, getEventSafely(state))) {
      return LazySplashScreen;
    }

    return LazyMarketMain;
  },
  help: true,
  backgroundImage: (state, customization) => {
    if (
      isSplashScreenShown(state, getEventSafely(state)) ||
      !getEventSafely(state) ||
      !getUser.select('me')(state).data
    ) {
      return null;
    }

    // PAX-4719: Change this to the advanced customization options
    return customization?.desktop_market_image || null;
  },
  redirect: either(
    redirectWhenRedirectAndAuthenticated,
    either(
      redirectToSignupUnlessCompleted,
      either(
        redirectToEmailValidationUnlessValidated,
        redirectToLoginIfOnboardingCompletedOnDevice,
      ),
    ),
  ),
};

export const peopleFeedRoute: RouteData = {
  path: '/people/',
  template: FullWidthTemplate,
  main: LazyMarketMain,
  help: true,
  backgroundImage: (_state, customization) =>
    customization?.desktop_market_image || null,
  redirect: standardAuthenticatedPageRedirect,
};

export const onlineUsersRoute: RouteData = {
  path: '/people/online/',
  template: FullWidthTemplate,
  main: LazyOnlineUsersMain,
  help: true,
  redirect: standardAuthenticatedPageRedirect,
};

export const signupRoute: RouteData = {
  path: '/signup/',
  template: (state) => {
    if (isPermissionScreenShown(state) || !!getAuthenticationToken(state)) {
      return BlankWithDesktopSideBarTemplate;
    }

    return OnboardingTemplate;
  },
  main: (state) => {
    if (isPermissionScreenShown(state)) {
      return LazyPermissionMain;
    }

    return LazySignupMain;
  },
  redirect: either(
    redirectToEmailValidationUnlessValidated,
    redirectToHomeWhenSignupCompleted,
  ),
  help: false,
  scrollToTop: true,
};

export const loginRoute: RouteData = {
  path: loginPath,
  template: OnboardingTemplate,
  main: LazyLoginMain,
  redirect: redirectToLoginURL,
  scrollToTop: true,
  help: (state) => {
    const event = getEventSafely(state);
    return event && !isEventContextOnSite(event);
  },
  allowAbsoluteURLRedirect: true,
};

export const validateEmailRoute: RouteData = {
  path: '/validate-email/',
  template: OnboardingTemplate,
  main: LazyValidateEmailMain,
  redirect: redirectToHomeWhenEmailValidated,
  scrollToTop: true,
  allowAbsoluteURLRedirect: true,
};

export const loginSupportRoute: RouteData = {
  path: '/login-support/',
  template: OnboardingTemplate,
  main: LazyLoginSupportMain,
  redirect: standardUnauthenticatedPageRedirect,
  scrollToTop: true,
};

export const retrieveUsernameRoute: RouteData = {
  path: '/login-support/username/',
  template: OnboardingTemplate,
  main: LazyRetrieveUsernameMain,
  redirect: standardUnauthenticatedPageRedirect,
  scrollToTop: true,
};

export const loginLinkRoute: RouteData = {
  path: '/login-support/link/',
  template: OnboardingTemplate,
  main: LazyLoginLinkMain,
  redirect: standardUnauthenticatedPageRedirect,
  scrollToTop: true,
};

export const contactRoute: RouteData = {
  path: '/support-contact/',
  template: OnboardingTemplate,
  main: LazySupportContactMain,
  redirect: standardUnauthenticatedPageRedirect,
  scrollToTop: true,
};

export const passwordSupportRoute: RouteData = {
  path: '/password-support/',
  template: OnboardingTemplate,
  main: LazyPasswordSupportMain,
  redirect: standardUnauthenticatedPageRedirect,
  scrollToTop: true,
};

export const resetPasswordRoute: RouteData = {
  path: '/reset-password/',
  template: OnboardingTemplate,
  main: LazyResetPasswordMain,
  redirect: standardUnauthenticatedPageRedirect,
  scrollToTop: true,
};

export const topicRoute: RouteData = {
  path: '/topics/:id/',
  template: StretchedWidthTemplate,
  main: LazyTopicDetailsMain,
  redirect: standardAuthenticatedPageRedirect,
  scrollToTop: true,
};

export const topicEditRoute: RouteData = {
  path: '/topics/:id/edit/',
  template: StretchedWidthTemplate,
  main: LazyTopicEditMain,
  redirect: standardAuthenticatedPageRedirect,
  scrollToTop: true,
};

export const topicInviteRoute: RouteData = {
  path: '/topics/:id/invite/:userId',
  template: FullWidthTemplate,
  main: LazyTopicDetailsMain,
  redirect: standardAuthenticatedPageRedirect,
  scrollToTop: true,
};

export const newTopicRoute: RouteData = {
  path: '/new-topic/',
  template: BlankTemplate,
  main: LazyNewTopicMain,
  redirect: standardAuthenticatedPageRedirect,
  scrollToTop: true,
};

export const newTopicNoPermissionRoute: RouteData = {
  path: '/new-topic-no-permission/',
  template: FullWidthTemplate,
  main: LazyNewTopicNoPermissionMain,
  redirect: standardAuthenticatedPageRedirect,
  scrollToTop: true,
};

export const braindatesRoute: RouteData = {
  path: `/braindates/`,
  template: FullWidthTemplate,
  main: LazyBraindatesMain,
  redirect: standardAuthenticatedPageRedirect,
  backgroundImage: (_state, customization) =>
    customization?.desktop_my_braindates_image || null,
  help: true,
};

// @deprecated
export const braindatesInvitationsRoute: RouteData = {
  path: `/braindates/invitations/`,
  template: null,
  main: null,
  redirect: () => braindatesRoute.path,
};

export const pendingBraindatesRoute: RouteData = {
  path: `/braindates/pending/`,
  template: FullWidthTemplate,
  main: LazyBraindatesMain,
  redirect: standardAuthenticatedPageRedirect,
  backgroundImage: (_state, customization) =>
    customization?.desktop_my_braindates_image || null,
  help: true,
};

export const confirmedBraindatesRoute: RouteData = {
  path: `/braindates/confirmed/`,
  template: FullWidthTemplate,
  main: LazyBraindatesMain,
  redirect: standardAuthenticatedPageRedirect,
  backgroundImage: (_state, customization) =>
    customization?.desktop_my_braindates_image || null,
  help: true,
};

export const cheersRoute: RouteData = {
  path: '/cheers/',
  template: FullWidthTemplate,
  main: LazyBraindatesMain,
  redirect: standardAuthenticatedPageRedirect,
};

// #region - Deprecated: To be removed in the future
const redirectToCheers = () => cheersRoute.path;

export const expressionOfGratitudeRoute: RouteData = {
  path: '/expression-of-gratitude/',
  template: FullWidthTemplate,
  main: LazyBraindatesMain,
  redirect: standardAuthenticatedPageRedirect,
};

export const expressionOfGratitudeDetailsRoute: RouteData = {
  path: '/expression-of-gratitude/:id',
  template: StretchedWidthTemplate,
  main: LazyBraindatesMain,
  redirect: redirectToCheers,
};

export const newExpressionOfGratitudeRoute: RouteData = {
  path: '/expression-of-gratitude/grant/',
  template: StretchedWidthTemplate,
  main: LazyBraindatesMain,
  redirect: redirectToCheers,
};

export const newExpressionOfGratitudeWithBraindateRoute: RouteData = {
  path: '/expression-of-gratitude/grant/:braindateId',
  template: StretchedWidthTemplate,
  main: LazyBraindatesMain,
  redirect: redirectToCheers,
};

export const newExpressionOfGratitudeWithBraindateAndUserRoute: RouteData = {
  path: '/expression-of-gratitude/grant/:braindateId/:userId',
  template: StretchedWidthTemplate,
  main: LazyBraindatesMain,
  redirect: redirectToCheers,
};
// #endregion

export const braindateRoute: RouteData = {
  path: `/braindates/:id/`,
  template: StretchedWidthTemplate,
  main: LazyBraindateMain,
  help: false,
  redirect: standardAuthenticatedPageRedirect,
};

// Maybe deprecated in the future
export const conversationRoute: RouteData = {
  path: '/topics/:id/conversation/',
  template: StretchedWidthTemplate,
  main: LazyConversationMain,
  redirect: standardAuthenticatedPageRedirect,
};

export const preferencesRoute: RouteData = {
  path: '/preferences/',
  template: StretchedWidthTemplate,
  main: LazyPreferencesMain,
  help: true,
  redirect: standardAuthenticatedPageRedirect,
  scrollToTop: true,
};

export const notificationsPreferencesRoute: RouteData = {
  path: '/preferences/notifications/',
  template: StretchedWidthTemplate,
  main: LazyNotificationsPreferencesMain,
  help: true,
  redirect: standardAuthenticatedPageRedirect,
  scrollToTop: true,
};

export const userRoute: RouteData = {
  path: '/users/:id/',
  template: StretchedWidthTemplate,
  main: LazyUserMain,
  redirect: standardAuthenticatedPageRedirect,
  scrollToTop: true,
};

export const userInviteRoute: RouteData = {
  path: '/users/:id/invite',
  template: FullWidthTemplate,
  main: LazyInviteMain,
  redirect: standardAuthenticatedPageRedirect,
  scrollToTop: true,
};

export const profileRoute: RouteData = {
  path: '/account/profile/',
  template: StretchedWidthTemplate,
  main: LazyProfileMain,
  redirect: standardAuthenticatedPageRedirect,
  scrollToTop: true,
  help: true,
};

export const ownTopicsRoute: RouteData = {
  path: '/account/topics/',
  template: StretchedWidthTemplate,
  main: LazyOwnTopicsMain,
  help: true,
  redirect: standardAuthenticatedPageRedirect,
  scrollToTop: true,
};

export const ownTopicsActiveRoute: RouteData = {
  path: '/account/topics/active',
  template: StretchedWidthTemplate,
  main: LazyOwnTopicsMain,
  help: true,
  redirect: standardAuthenticatedPageRedirect,
  scrollToTop: true,
};

export const ownTopicsHiddenRoute: RouteData = {
  path: '/account/topics/hidden',
  template: StretchedWidthTemplate,
  main: LazyOwnTopicsMain,
  help: true,
  redirect: standardAuthenticatedPageRedirect,
  scrollToTop: true,
};

export const ownTopicsRejectedRoute: RouteData = {
  path: '/account/topics/rejected',
  template: StretchedWidthTemplate,
  main: LazyOwnTopicsMain,
  help: true,
  redirect: standardAuthenticatedPageRedirect,
  scrollToTop: true,
};

export const ownTopicsPendingRoute: RouteData = {
  path: '/account/topics/pending',
  template: StretchedWidthTemplate,
  main: LazyOwnTopicsMain,
  help: true,
  redirect: standardAuthenticatedPageRedirect,
  scrollToTop: true,
};

export const availabilityRoute: RouteData = {
  path: '/account/availability/',
  template: StretchedWidthTemplate,
  main: LazyAvailabilityMain,
  help: true,
  redirect: standardAuthenticatedPageRedirect,
  scrollToTop: true,
};

export const profileEditRoute: RouteData = {
  path: '/account/profile/edit/',
  template: StretchedWidthTemplate,
  main: LazyProfileEditMain,
  redirect: standardAuthenticatedPageRedirect,
  scrollToTop: false,
  help: true,
};

export const faqRoute: RouteData = {
  path: '/faq/',
  template: FullWidthTemplate,
  main: LazyFAQMain,
  redirect: standardAuthenticatedPageRedirect,
  scrollToTop: true,
};

export const codeOfConductRoute: RouteData = {
  path: '/code-of-conduct/',
  template: (state) => {
    if (getAuthenticationToken(state)) {
      return FullWidthTemplate;
    }
    return OnboardingTemplate;
  },
  main: LazyCodeOfConductMain,
  scrollToTop: true,
};

export const supportRoute: RouteData = {
  path: '/support/',
  template: FullWidthTemplate,
  main: LazySupportMain,
  redirect: standardAuthenticatedPageRedirect,
  scrollToTop: true,
};

export const oauthRedirect: RouteData = {
  path: '/oauth2/redirect/',
  template: BlankTemplate,
  main: LazyOauthRedirect,
};

export const serverErrorRoute: RouteData = {
  path: '/server-error/',
  template: BlankTemplate,
  main: LazyServerErrorMain,
};

export const clientErrorRoute: RouteData = {
  path: '/client-error/',
  template: BlankTemplate,
  main: LazyClientErrorMain,
};

export const lightRoute: RouteData = {
  path: '/light/',
  template: BlankTemplate,
  main: LazyLightMain,
};

export const blankRoute: RouteData = {
  path: '/blank/',
  template: BlankTemplate,
  main: LazyBlankMain,
};

export const blankWithDesktopSidebarRoute: RouteData = {
  path: '/blank-with-desktop-sidebar/',
  template: BlankWithDesktopSideBarTemplate,
  main: LazyBlankMain,
};

export const stretchedNavRoute: RouteData = {
  path: '/streteched/',
  template: StretchedWidthTemplate,
  main: LazyBlankMain,
};

export const fullWidthNavRoute: RouteData = {
  path: '/full-width/',
  template: FullWidthTemplate,
  main: LazyBlankMain,
};

// DON'T DEFINED NEW ROUTES UNDER THIS LINE

export const notFoundRoute: RouteData = {
  path: '*',
  template: FullWidthTemplate,
  main: LazyNotFoundMain,
  scrollToTop: true,
};

export default {
  homeRoute,
  peopleFeedRoute,
  onlineUsersRoute,
  validateEmailRoute,
  signupRoute,
  loginRoute,
  loginSupportRoute,
  retrieveUsernameRoute,
  loginLinkRoute,
  contactRoute,
  passwordSupportRoute,
  resetPasswordRoute,
  topicRoute,
  topicEditRoute,
  topicInviteRoute,
  newTopicRoute,
  newTopicNoPermissionRoute,
  braindatesRoute,
  braindatesInvitationsRoute,
  pendingBraindatesRoute,
  confirmedBraindatesRoute,
  braindateRoute,
  conversationRoute,
  preferencesRoute,
  notificationsPreferencesRoute,
  userRoute,
  userInviteRoute,
  profileRoute,
  ownTopicsRoute,
  ownTopicsActiveRoute,
  ownTopicsHiddenRoute,
  ownTopicsRejectedRoute,
  ownTopicsPendingRoute,
  availabilityRoute,
  profileEditRoute,
  faqRoute,
  codeOfConductRoute,
  supportRoute,
  oauthRedirect,
  serverErrorRoute,
  clientErrorRoute,
  lightRoute,
  cheersRoute,

  blankRoute,
  blankWithDesktopSidebarRoute,
  stretchedNavRoute,
  fullWidthNavRoute,

  notFoundRoute,
};
