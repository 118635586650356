/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { getUserFirstName } from '@braindate/domain/lib/user/util';

import { ampli } from 'src/ampli';
import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import { newTopicRoute } from 'src/shared/app/base/route/setting/routeSettings';
import type { Notification } from 'src/shared/app/notification/type/notificationTypes';
import messages from 'src/shared/app/topic/new/l10n/newTopicNotificationL10n';
import useSelf from 'src/shared/domain/user/hook/useSelf';

import ContentImg from '!@svgr/webpack?modules!src/shared/app/topic/new/assets/returning-modal.svg';

import NewTopicNotificationModal from './NewTopicNotificationModal';

type Props = {
  notification: Notification<>,
};

const NewTopicNotificationReturning = ({ notification }: Props): Node => {
  const intl = useIntl();
  const navigate = useNavigate();
  const user = useSelf();

  const [newTopicPath] = useRoutes(newTopicRoute);

  const handleAccept = () => {
    ampli.topicCreationStart({ Origin: 'Notification Returning' });
    navigate(newTopicPath);
  };

  return (
    <NewTopicNotificationModal
      notification={notification}
      title={intl.formatMessage(messages.returningTitle, {
        firstname: user && getUserFirstName(user),
      })}
      subtitle={intl.formatMessage(messages.returningSubtitle)}
      content={intl.formatMessage(messages.returningContent)}
      image={
        <div>
          {/* $FlowIssue */}
          <ContentImg />
        </div>
      }
      acceptCTA={intl.formatMessage(messages.addTopic)}
      closeCTA={intl.formatMessage(messages.later)}
      onAccept={handleAccept}
    />
  );
};

export default memo<Props>(NewTopicNotificationReturning);
