/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 5
 * Build: 1.0.0
 * Runtime: browser:javascript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/e180/Braindate/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/e180/Braindate/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

/**
 * @typedef BaseEvent
 * @type {object}
 * @property {string} event_type
 * @property {Object.<string, *>} [event_properties]
 */

/**
 * @typedef Plan
 * @type {object}
 * @property {string} [branch]
 * @property {string} [source]
 * @property {string} [version]
 * @property {string} [versionId]
 */

/**
 * @typedef LoadClientOptions
 * @type {object}
 * @property {string} [apiKey]
 * @property {Object} [configuration]
 * @property {BrowserClient} [instance]
 */

/**
 * @typedef LoadOptions
 * @type {object}
 * @property {'braindate'} [environment]
 * @property {boolean} [disabled]
 * @property {LoadClientOptions} [client]
 */

/**
 * @typedef {Object} EventOptions
 * @type {object}
 */

/**
 * @typedef ApiKey
 * @type {object}
 * @property {string} braindate
 */
export const ApiKey = {
  braindate: ''
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration = {
  plan: {
    version: '5',
    branch: 'main',
    source: 'web',
    versionId: '095ad3e2-7a3d-4f39-90cc-3ecf3e33b474'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-javascript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export class MenuMobileOpen {
  constructor() {
    this.event_type = 'Menu Mobile Open';
  }
}

export class NotificationOpen {
  constructor() {
    this.event_type = 'Notification Open';
  }
}

export class NotificationCleared {
  constructor() {
    this.event_type = 'notification_cleared';
  }
}

export class TopicCreationAiOpen {
  constructor() {
    this.event_type = 'Topic Creation AI Open';
  }
}

export class TopicCreationAiRestart {
  constructor() {
    this.event_type = 'Topic Creation AI Restart';
  }
}

export class TopicCreationAiSelect {
  constructor(properties) {
    this.event_type = 'Topic Creation AI Select';
    this.event_properties = properties;
  }
}

export class TopicCreationClose {
  constructor() {
    this.event_type = 'Topic Creation Close';
  }
}

export class TopicCreationModalClose {
  constructor() {
    this.event_type = 'Topic Creation Modal Close';
  }
}

export class TopicCreationModalShare {
  constructor() {
    this.event_type = 'Topic Creation Modal Share';
  }
}

export class TopicCreationPost {
  constructor() {
    this.event_type = 'Topic Creation Post';
  }
}

export class TopicCreationStart {
  constructor(properties) {
    this.event_type = 'Topic Creation Start';
    this.event_properties = properties;
  }
}

export class TopicCreationStep1 {
  constructor() {
    this.event_type = 'Topic Creation Step 1';
  }
}

export class TopicCreationStep2 {
  constructor() {
    this.event_type = 'Topic Creation Step 2';
  }
}

export class TopicCreationStep2ChangeTitle {
  constructor() {
    this.event_type = 'Topic Creation Step 2 Change Title';
  }
}

export class TopicCreationStep2ConfirmTime {
  constructor() {
    this.event_type = 'Topic Creation Step 2 Confirm Time';
  }
}

export class TopicCreationStep3 {
  constructor() {
    this.event_type = 'Topic Creation Step 3';
  }
}

export class TopicCreationAiGenerated {
  constructor() {
    this.event_type = 'topic_creation_ai_generated';
  }
}

export class TopicCreationAiSent {
  constructor(properties) {
    this.event_type = 'topic_creation_ai_sent';
    this.event_properties = properties;
  }
}

/**
 * @typedef PromiseResult
 * @type {object}
 * @property {Promise|undefined} promise
 */

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  constructor() {
    /* @type {BrowserClient|undefined} */
    this.amplitude = undefined;
    this.disabled = false;
  }

  /**
   * @return {BrowserClient}
   */
  get client() {
    this.isInitializedAndEnabled();
    return this.amplitude;
  }

  /**
   * @return {boolean}
   */
  get isLoaded() {
    return this.amplitude != null;
  }

  /**
   * @private
   * @return {boolean}
   */
  isInitializedAndEnabled() {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param {LoadOptions} options Configuration options to initialize the Ampli SDK with. 'environment', 'client.apiKey' or 'client.instance' is required.
   *
   * @return {PromiseResult}
   */
  load(options) {
    this.disabled = options?.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey;
    if (options?.client?.apiKey) {
      apiKey = options.client.apiKey;
    } else if (options?.environment) {
      apiKey = ApiKey[options.environment];
    }

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...options?.client?.configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user.
   *
   * @param {string|undefined} userId The user's id.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  identify(userId, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options, user_id: userId};
    }

    const ampIdentify = new amplitude.Identify();
    return this.amplitude.identify(ampIdentify, options);
  }

  /**
  * Flush the event.
  */
  flush() {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.flush();
  }

  /**
   * Track event
   *
   * @param {BaseEvent} event The event to track.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  track(event, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.track(event, undefined, options);
  }

  /**
   * Menu Mobile Open
   *
   * [View in Tracking Plan](https://data.amplitude.com/e180/Braindate/events/main/latest/Menu%20Mobile%20Open)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  menuMobileOpen(options) {
    return this.track(new MenuMobileOpen(), options);
  }

  /**
   * Notification Open
   *
   * [View in Tracking Plan](https://data.amplitude.com/e180/Braindate/events/main/latest/Notification%20Open)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  notificationOpen(options) {
    return this.track(new NotificationOpen(), options);
  }

  /**
   * notification_cleared
   *
   * [View in Tracking Plan](https://data.amplitude.com/e180/Braindate/events/main/latest/notification_cleared)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  notificationCleared(options) {
    return this.track(new NotificationCleared(), options);
  }

  /**
   * Topic Creation AI Open
   *
   * [View in Tracking Plan](https://data.amplitude.com/e180/Braindate/events/main/latest/Topic%20Creation%20AI%20Open)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  topicCreationAiOpen(options) {
    return this.track(new TopicCreationAiOpen(), options);
  }

  /**
   * Topic Creation AI Restart
   *
   * [View in Tracking Plan](https://data.amplitude.com/e180/Braindate/events/main/latest/Topic%20Creation%20AI%20Restart)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  topicCreationAiRestart(options) {
    return this.track(new TopicCreationAiRestart(), options);
  }

  /**
   * Topic Creation AI Select
   *
   * [View in Tracking Plan](https://data.amplitude.com/e180/Braindate/events/main/latest/Topic%20Creation%20AI%20Select)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.Keywords Property has no description in tracking plan.
   * @param {string} properties.Value Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  topicCreationAiSelect(properties, options) {
    return this.track(new TopicCreationAiSelect(properties), options);
  }

  /**
   * Topic Creation Close
   *
   * [View in Tracking Plan](https://data.amplitude.com/e180/Braindate/events/main/latest/Topic%20Creation%20Close)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  topicCreationClose(options) {
    return this.track(new TopicCreationClose(), options);
  }

  /**
   * Topic Creation Modal Close
   *
   * [View in Tracking Plan](https://data.amplitude.com/e180/Braindate/events/main/latest/Topic%20Creation%20Modal%20Close)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  topicCreationModalClose(options) {
    return this.track(new TopicCreationModalClose(), options);
  }

  /**
   * Topic Creation Modal Share
   *
   * [View in Tracking Plan](https://data.amplitude.com/e180/Braindate/events/main/latest/Topic%20Creation%20Modal%20Share)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  topicCreationModalShare(options) {
    return this.track(new TopicCreationModalShare(), options);
  }

  /**
   * Topic Creation Post
   *
   * [View in Tracking Plan](https://data.amplitude.com/e180/Braindate/events/main/latest/Topic%20Creation%20Post)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  topicCreationPost(options) {
    return this.track(new TopicCreationPost(), options);
  }

  /**
   * Topic Creation Start
   *
   * [View in Tracking Plan](https://data.amplitude.com/e180/Braindate/events/main/latest/Topic%20Creation%20Start)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {'Create Button'|'Modal'} properties.Topic Create Form Origin Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  topicCreationStart(properties, options) {
    return this.track(new TopicCreationStart(properties), options);
  }

  /**
   * Topic Creation Step 1
   *
   * [View in Tracking Plan](https://data.amplitude.com/e180/Braindate/events/main/latest/Topic%20Creation%20Step%201)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  topicCreationStep1(options) {
    return this.track(new TopicCreationStep1(), options);
  }

  /**
   * Topic Creation Step 2
   *
   * [View in Tracking Plan](https://data.amplitude.com/e180/Braindate/events/main/latest/Topic%20Creation%20Step%202)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  topicCreationStep2(options) {
    return this.track(new TopicCreationStep2(), options);
  }

  /**
   * Topic Creation Step 2 Change Title
   *
   * [View in Tracking Plan](https://data.amplitude.com/e180/Braindate/events/main/latest/Topic%20Creation%20Step%202%20Change%20Title)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  topicCreationStep2ChangeTitle(options) {
    return this.track(new TopicCreationStep2ChangeTitle(), options);
  }

  /**
   * Topic Creation Step 2 Confirm Time
   *
   * [View in Tracking Plan](https://data.amplitude.com/e180/Braindate/events/main/latest/Topic%20Creation%20Step%202%20Confirm%20Time)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  topicCreationStep2ConfirmTime(options) {
    return this.track(new TopicCreationStep2ConfirmTime(), options);
  }

  /**
   * Topic Creation Step 3
   *
   * [View in Tracking Plan](https://data.amplitude.com/e180/Braindate/events/main/latest/Topic%20Creation%20Step%203)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  topicCreationStep3(options) {
    return this.track(new TopicCreationStep3(), options);
  }

  /**
   * topic_creation_ai_generated
   *
   * [View in Tracking Plan](https://data.amplitude.com/e180/Braindate/events/main/latest/topic_creation_ai_generated)
   *
Event has no description in tracking plan.
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  topicCreationAiGenerated(options) {
    return this.track(new TopicCreationAiGenerated(), options);
  }

  /**
   * topic_creation_ai_sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/e180/Braindate/events/main/latest/topic_creation_ai_sent)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.value Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  topicCreationAiSent(properties, options) {
    return this.track(new TopicCreationAiSent(properties), options);
  }
}

export const ampli = new Ampli();
